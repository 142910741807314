@use "../var";
@import "../mixins";
@import "../colors";
@import "../theme";
.header-container {
	z-index: 99;
	@include flex-container(row, center, center, nowrap);
	height: 100%;
	padding: 0 2rem;

	#header-logo {
		height: 70%;
		background-color: $light--foreground;
		padding: 0.25rem;
		border-radius: 50%;
	}
	h4 {
		margin-left: 0.5rem;
	}
	.header-navbar-container {
		margin-left: auto;
		margin-right: auto;
		button {
			border-radius: 0.25rem;
			font-size: 1.5rem;
			padding: 1rem 2rem;
			margin: 0.25rem 0rem;
			text-align: center;
			.icon {
				@include themed() {
					color: t(var.$border);
				}
			}
			.selected {
				@include themed() {
					color: t(var.$text);
				}
			}
			p {
				font-size: 1rem;
				@include themed() {
					color: t(var.$text-secondary);
				}
			}
		}
		button:hover {
			@include themed() {
				background-color: t(var.$inactive);
			}
			p {
				@include themed() {
					color: t(var.$text-secondary);
				}
			}
			transition: background-color 200ms ease-in-out;
		}
	}
	.header-profile-container {
		.header-profile-content {
			@include themed() {
				background-color: t(var.$bg);
			}
			height: 90%;
			border-radius: 1.25rem;
			@include flex-container(row, center, center, nowrap);

			p {
				padding: 0.1rem 0.75rem;
			}
			.profile-picture {
				padding: 0rem 0rem;
			}
		}
		.header-profile-content:hover {
			@include themed() {
				background-color: t(var.$inactive);
			}
		}

		.drop-down-container {
			@include flex-container(column, flex-start, flex-start, nowrap);
			@include themed() {
				background-color: t(var.$bg);
			}
			box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.4);
			position: absolute;
			right: 2rem;
			top: 4rem;
			z-index: 100;
			border-radius: 0.25rem;

			padding: 0.5rem 0;
			min-width: 12rem;

			button {
				@include flex-container(row, flex-start, center, nowrap);

				padding: 0.45rem 1rem;
				margin: 0.05rem 0;
				width: 100%;
				text-align: left;
				font-size: 1.25rem;
				@include themed() {
					color: t(var.$text);
				}
				@include themed() {
					background-color: t(var.$bg);
				}
				.icon {
					padding-right: 0.5rem;
				}
				.arrow {
					margin-left: auto;
				}
				.MuiIcon-fontSizeInherit {
					font-size: inherit;
				}
				.MuiIcon-colorPrimary {
					color: inherit;
				}
			}
			button:hover {
				@include themed() {
					background-color: t(var.$fg);
				}
			}
		}
	}
}

.sub-header-container {
	justify-self: stretch;
	grid-column: 1/4;
	grid-row: 1/2;
	padding: 0.5rem 2rem;

	@include themed() {
		background-color: t(var.$text-input);
	}
	.home-sub-header,
	.group-sub-header,
	.explore-sub-header,
	.profile-sub-header {
		width: min(100%, 200rem);
		height: 100%;
		@include flex-container(row, center, center, nowrap);
		h4 {
			margin-left: auto;
			margin-right: auto;
		}
		.buttons {
			a {
				margin: 0 0.25rem;
			}
		}
	}
	.home-sub-header {
		.filter-container {
			@include flex-container(row, flex-start, center, nowrap);
			.filter-setting {
				@include flex-container(row, flex-start, center, nowrap);
				.icon {
					margin: 0 0.25rem 0 0.5rem;
				}
				h6 {
					text-transform: uppercase;
				}
			}

			.filter-drop-down-container {
				@include flex-container(column, flex-start, flex-start, nowrap);
				@include themed() {
					background-color: t(var.$bg);
				}
				box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.4);
				position: absolute;
				left: 2rem;
				top: 8rem;
				z-index: 100;
				border-radius: 0.25rem;

				padding: 0.5rem 0;
				min-width: 12rem;
				button {
					@include flex-container(row, flex-start, center, nowrap);

					padding: 0.45rem 1rem;
					margin: 0.05rem 0;
					width: 100%;
					text-align: left;
					font-size: 1.25rem;
					@include themed() {
						color: t(var.$text);
					}
					@include themed() {
						background-color: t(var.$bg);
					}
					.icon {
						padding-right: 0.5rem;
					}
					.arrow {
						margin-left: auto;
					}
					.MuiIcon-fontSizeInherit {
						font-size: inherit;
					}
					.MuiIcon-colorPrimary {
						color: inherit;
					}
				}
				button:hover {
					@include themed() {
						background-color: t(var.$fg);
					}
				}
			}
		}
	}
	.profile-sub-header {
		.buttons {
			margin-left: auto;
		}
	}
}
