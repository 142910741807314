@use "../var";
@import "../colors";
@import "../theme";
@import "../mixins";

.nav-header {
	width: 100%;
	height: 4rem;
	padding: 0.5rem;
	position: relative;
	top: 0;
	@include flex-container(row, flex-start, center, nowrap);
	@include themed() {
		background-color: t(var.$primary-default);
	}
	img {
		width: 2rem;
		height: 2rem;
	}
	h4,
	h5 {
		margin-left: 0.5rem;
	}
}
.nav-button-container {
	width: 100%;
	.button {
		width: 95%;
		padding: 0.35rem;
		margin: 0.35rem;
		@include themed() {
			color: t(var.$text);
		}
		@include flex-container(row, flex-start, center, nowrap);

		p {
			text-align: left;
			@include text(var.$font-title, var.$button);
			padding: 4px;
			text-decoration: none;
		}
	}
	.button:hover {
		@include themed() {
			background-color: t(var.$primary-light);
			color: t(var.$light);
		}
	}
	.button:hover p {
		@include themed() {
			color: t(var.$light);
		}
	}
}
.nav-button-container.bottom {
	position: absolute;
	bottom: 0;
	@include themed() {
		background-color: t(var.$fg);
	}
}
.groups-container {
	width: 100%;
	.group-title {
		@include themed() {
			background-color: t(var.$primary-default);
			color: t(var.$light);
		}
		padding: 0.5rem 0.75rem;
		padding-left: 1rem;
		margin: 0;
	}
	hr {
		margin-left: -10px;
		margin-right: 10px;
	}
	.nav-button-container {
		width: 100%;
		text-align: left;

		.group-nav-card {
			@include flex-container(row, space-between, center, nowrap);
			width: 100%;
			padding: 0.5rem 0.75rem;
			// padding-right: 1rem;
			margin: 0.1rem;
			border-radius: 0.25rem;
			.content {
				width: 100%;
				@include flex-container(row, flex-start, center, nowrap);
				h6 {
					margin: 0 0.75rem;
					text-align: left;
				}
				.icon {
					@include themed() {
						color: t(var.$border);
					}
				}
				.icon:hover {
					transform: scale(1.2);
				}
			}
		}
		.group-nav-card:hover {
			@include themed() {
				background-color: t(var.$inactive);
			}
		}
	}
}
