@use "../var";
@import "../colors";
@import "../theme";
@import "../typography";

.explore-page {
	.updates {
		button {
			margin: 0 0.25rem;
		}
	}
	.latest-update {
		button {
			margin-right: auto;
		}
	}
	.topics {
		.shadow-card {
			li {
				width: 100%;
				.topic-card {
					width: 100%;
					padding: 0.5rem 0.75rem;
					margin: 0.1rem;
					border-radius: 0.25rem;
					@include flex-container(row, flex-start, center, nowrap);
					@include themed() {
						color: t(var.$text);
					}
					.stats-container {
						margin-left: auto;
						padding: 0.15rem;
						border-radius: 0.5rem;
						@include flex-container(row, flex-start, center, nowrap);
						// @include themed() {
						// 	background-color: t(var.$bg);
						// }
						.icon {
							margin: 0 0.25rem 0 0.5rem;
						}
					}
				}
				.topic-card:hover {
					@include themed() {
						background-color: t(var.$inactive);
					}
				}
			}
		}
	}
}
