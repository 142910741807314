@use '../var';
@import "../mixins";
@import "../colors";
@import "../theme";

.focus-post-wrapper {
	@include themed() {
		background-color: t(var.$bg);
	}
	overflow-y: scroll;

	height: 100%;
	padding: 0.25rem;
	.hidden {
		display: none;
	}
	.sub-header {
		padding: 1rem;
		border-radius: 0.25rem;

		text-align: center;
		@include themed() {
			background-color: t(var.$fg);
		}
		h3 {
			padding: 1rem;
		}
		.app-store {
			width: clamp(5rem, 50%, 15rem);
			padding: 0.5rem;
		}
		p {
			padding: 1rem;
		}
	}
	.modal-container {
		z-index: 10;
		position: absolute;
		width: 100%;
		height: 100%;
		@include flex-container(column, center, center, nowrap);
		.modal {
			padding: 1rem;
			border-radius: 0.25rem;
			width: min(80%, 30rem);
			text-align: center;
			@include themed() {
				background-color: t(var.$fg);
			}
			h3 {
				padding: 1rem;
			}
			.app-store {
				width: clamp(5rem, 50%, 15rem);
				padding: 0.5rem;
			}
			p {
				padding: 1rem;
			}
		}
	}

	.blurred {
		filter: blur(5px);
	}
	.card {
		@include themed() {
			background-color: t(var.$fg);
		}
		.card-content {
			width: 100%;
			.card-header {
				.text {
					.bottom {
						.title {
							@include themed() {
								background-color: t(var.$bg);
							}
						}
					}
				}
			}
		}
		.option-container {
			max-height: 60rem;
			// padding-bottom: 50rem;
			overflow-y: scroll;
		}
	}
	.reply-container {
		margin: 0 auto;
		width: clamp(20rem, 100%, 55rem);
		.reply {
			padding: 0.5rem;
			margin: 0.25rem;
			border-radius: 0.25rem;
			@include flex-container(row, flex-start, center, nowrap);
			@include themed() {
				background-color: t(var.$fg);
			}
			.reply-content {
				padding-left: 0.5rem;
				.reply-header {
					width: 100%;
					@include flex-container(row, flex-start, center, nowrap);
				}
			}
		}
	}
}
.card {
	padding: 0.5rem 1rem 0.5rem 0rem;
	margin: 0.5rem auto;
	border-radius: 0.5rem;
	width: clamp(20rem, 100%, 55rem);
	overflow: hidden;
	text-align: left;
	@include themed() {
		background-color: t(var.$bg);
	}
	@include flex-container(row, center, center, nowrap);
	.accent-line {
		height: 60%;
		width: 0.5rem;
		flex: 1;
		position: absolute;
		background-color: blue;
	}

	.card-content {
		padding-left: 1rem;
		width: 100%;
		z-index: 1;
		.card-header {
			z-index: 2;
			@include flex-container(row, flex-start, center, nowrap);
			.picture {
				width: 3.5rem;
				height: 3.5rem;
				margin: 0.25rem;
			}
			.text {
				.top {
					margin-left: 0.35rem;

					@include flex-container(row, flex-start, center, wrap);
				}
				.bottom {
					@include flex-container(row, flex-start, center, nowrap);
					.topic,
					.title {
						margin-left: 0.35rem;
						padding: 0.25rem 0.5rem;
						width: max-content;
						border-radius: 0.25rem;
					}
					.topic {
						@include themed() {
							background-color: t(var.$primary-default);
						}
					}
					.title {
						@include themed() {
							background-color: t(var.$fg);
						}
						p {
							color: var.$purple;
						}
					}
				}
			}
		}
		.card-body {
			z-index: 2;
			h6 {
				padding: 0.5rem 1rem 0.5rem 0;
			}
		}
		.option-wrapper {
			z-index: 2;
			width: 100%;
			margin: 0.5rem 0rem;
			.option {
				overflow: hidden;
				width: 100%;
				position: relative;
				@include flex-container(row, space-between, center, nowrap);
				border-width: 2px;
				border-style: solid;

				border-radius: 0.25rem;
				@include themed() {
					border-color: t(var.$border);
				}
				p {
					z-index: 2;
					padding: 0.5rem;
				}
				.result {
					border-width: 1px;
					border-style: solid;
					padding: 0.25rem 1rem;
					border-radius: 0.5rem;
					margin: 0.5rem;
					@include themed() {
						border-color: t(var.$border);
					}
				}
				.percentage-bar {
					@include themed() {
						background-color: t(var.$inactive);
					}
					height: 30rem;
					width: 20rem;
					margin-bottom: -3rem;
					position: absolute;
					z-index: 1;
				}
			}
			.voted {
				@include themed() {
					border-color: t(var.$primary-dark);
				}
				.percentage-bar {
					@include themed() {
						background-color: t(var.$primary-light);
					}
				}
			}
		}
		.card-footer {
			z-index: 2;
			padding: 0.5rem;
			@include flex-container(row, space-between, center, nowrap);
			.chats {
				@include flex-container(row, flex-start, center, nowrap);
				p {
					padding-right: 0.5rem;
				}
			}
		}
	}
}
.update-card {
	width: 100%;
	box-shadow: 1px 1px 3px 0.5px rgba(0, 0, 0, 0.25);
	border-radius: 0.5rem;
	overflow: hidden;
	margin: 0.5rem 0;
	@include themed() {
		background-color: t(var.$bg);
	}
	.update-header {
		width: max-content;
		border-bottom-right-radius: 0.5rem;
		@include flex-container(row, flex-start, center, nowrap);
		@include themed() {
			background-color: t(var.$fg);
		}
		.stats-container {
			padding-right: 1rem;
			@include flex-container(row, flex-end, center, nowrap);
			div {
				padding: 0rem 0.5rem;
				p {
					@include text(var.$font-title, var.$default);
				}
				.icon {
					@include themed() {
						color: t(var.$text-secondary);
						margin-right: 0.25rem;
					}
				}
			}
		}

		.name-tag {
			position: relative;
			top: 0;
			left: 0;
			padding: 0.5rem 1rem 0.5rem;
			width: max-content;
			border-bottom-right-radius: 0.5rem;
			@include flex-container(row, flex-start, center, nowrap);
			@include themed() {
				background-color: t(var.$primary-default);
			}
			.profile-pic {
				padding-right: 1rem;
			}
			p .author {
				padding-left: 1rem;
			}
		}
	}
	.content {
		padding: 1rem;
	}
}
.shadow-card {
	max-height: 100%;
	overflow: hidden;
	border-radius: 0.25rem;
	padding: 1rem;
	margin: 1rem 0.5rem;
	box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.4);
	@include flex-container(column, center, center, nowrap);
	@include themed() {
		background-color: t(var.$fg);
	}
	h3,
	h4 {
		margin-bottom: 1rem;
	}

	.text-field-wrapper {
		margin-top: 2rem;
		width: min(35rem, 100%);
		margin: 0 auto;
	}
}
.member-card {
	width: max-content;
	box-shadow: 1px 1px 3px 0.5px rgba(0, 0, 0, 0.25);
	border-radius: 1rem;
	padding: 0.75rem;
	margin: 0.5rem;
	@include flex-container(row, flex-start, center, nowrap);
	@include themed() {
		background-color: t(var.$bg);
	}
	.avatar {
		width: 4rem;
		height: 4rem;
	}
	.content {
		padding-left: 1rem;
	}
}
