@use './var';
@use './typography';
@use './componentStyles';
@import "./layouts";
@import "./mixins";
@import "./colors";
@import "./theme";
h1,
h2,
h3,
h4,
h5,
h6,
p {
	padding: 0;
	margin: 0;
}
* {
	box-sizing: border-box;
	font-size: 1rem;
}

#app-container {
	font-size: calc(60% + 0.8vmin);
	height: 100vh;
	overflow: hidden;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 4rem 1fr;

	#app-header {
		grid-column: 1/3;
		grid-row: 1/2;
		justify-self: stretch;
		@include themed() {
			background-color: t(var.$fg);
		}
		color: var.$light;
		@import "./components/header";
	}
	#app-content-container {
		justify-self: stretch;
		grid-column: 1/3;
		grid-row: 1/3;
		height: 100%;
		@include themed() {
			background-color: t(var.$bg);
			color: t(var.$text);
		}
		// @import "./components/container";
		// ^^ navbar component styling is in here
		.login-router {
			@import "./routers/login";
		}
		.home-router {
			height: 100%;
			@import "./routers/home";
			@import "./components/header";
		}
		.groups-router {
			@import "./routers/groups";
			@import "./components/header";
		}
		.explore-router {
			@import "./routers/explore";
			@import "./components/header";
		}
		.profile-router {
			@import "./routers/profile";
			@import "./components/header";
		}
	}
	// .container::-webkit-scrollbar {
	// 	background: transparent; /* Optional: just make scrollbar invisible */
	// 	width: 0; /* Remove scrollbar space */
	// }
}
// SHARED STYLES
.shadow-styles {
	box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.4);
}
.sub-container {
	padding: 1rem;
	margin: 1rem;
	@include flex-container(column, center, center, nowrap);
}
.dot {
	width: 0.5rem;
	height: 0.5rem;
	margin: 0 0.35rem;
	border-radius: 0.1rem;
	transform: rotateZ(45deg);
	@include themed() {
		background-color: t(var.$border);
	}
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scroll-container::-webkit-scrollbar {
	background: transparent;
	width: 0;
}

/* Hide scrollbar for IE and Edge */
.scroll-container {
	-ms-overflow-style: none;
}
.no-scroll {
	overflow: hidden;
}
.slicedID {
	opacity: 60%;
}

// STYLE: MISC
button {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	text-align: inherit;
}
// ^used to remove default styling from buttons
li {
	list-style-type: none;
}

.margin-auto {
	margin-left: auto;
	margin-right: auto;
}

@import "./components/header";
@import "./components/navbar";
@import "./components/cards";
