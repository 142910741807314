@use 'var';
@import "./theme";
@import "./mixins";

// STYLE: TEXT

h1 {
	@include text(var.$font-title, var.$XXL);
	@include themed() {
		color: t(var.$text);
	}
}

h2 {
	@include text(var.$font-title, var.$XL);

	@include themed() {
		color: t(var.$text);
	}
}

h3 {
	@include text(var.$font-title, var.$L);
	@include themed() {
		color: t(var.$text);
	}
}

h4 {
	@include text(var.$font-title, var.$M);
	@include themed() {
		color: t(var.$text);
	}
}
h5 {
	@include text(var.$font-body, var.$M);
	@include themed() {
		color: t(var.$text);
	}
	font-weight: 500;
}
h6 {
	@include text(var.$font-title, var.$default);
	@include themed() {
		color: t(var.$text);
	}
	font-weight: 500;
}

p {
	@include text(var.$font-body, var.$default);
	@include themed() {
		color: t(var.$text);
	}
}
p.bold {
	font-weight: 500;
	@include text(var.$font-body, var.$default);
	@include themed() {
		color: t(var.$text);
	}
}

p.small {
	@include text(var.$font-body, var.$S);
	@include themed() {
		color: t(var.$text);
	}
}

p.extra-small {
	@include text(var.$font-body, var.$XS);
	@include themed() {
		color: t(var.$text);
	}
}

h1.secondary,
h2.secondary,
h3.secondary,
h4.secondary,
h5.secondary,
h6.secondary,
p.secondary {
	@include themed() {
		color: t(var.$text-secondary);
	}
}
h1.center,
h2.center,
h3.center,
h4.center,
h5.center,
h6.center,
p.center {
	text-align: center;
}
h1.left,
h2.left,
h3.left,
h4.left,
h5.left,
h6.left,
p.left {
	text-align: left;
}
h1.right,
h2.right,
h3.right,
h4.right,
h5.right,
h6.right,
p.right {
	text-align: right;
}
h1.white,
h2.white,
h3.white,
h4.white,
h5.white,
h6.white,
p.white {
	@include themed() {
		color: t(var.$light);
	}
}
h1.black,
h2.black,
h3.black,
h4.black,
h5.black,
h6.black,
p.black {
	@include themed() {
		color: t(var.$dark);
	}
}
h1.purple,
h2.purple,
h3.purple,
h4.purple,
h5.purple,
h6.purple,
p.purple {
	@include themed() {
		color: t(var.$primary-default);
	}
}
h1.danger,
h2.danger,
h3.danger,
h4.danger,
h5.danger,
h6.danger,
p.danger {
	@include themed() {
		color: t(var.$danger);
	}
}
h1.faded,
h2.faded,
h3.faded,
h4.faded,
h5.faded,
h6.faded,
p.faded {
	opacity: 0.75;
}
h1.link,
h2.link,
h3.link,
h4.link,
h5.link,
h6.link,
p.link {
	@include themed() {
		color: t(var.$link);
	}
}
p.slicedID {
	opacity: 0.6;
}

// STYLE: LINKS
a {
	@include text(var.$font-body, var.$default);

	@include themed() {
		color: t(var.$link);
	}
}
