@mixin flex-container($direction, $justify, $align, $wrap) {
	display: flex;
	flex-direction: $direction;
	align-items: $align;
	justify-content: $justify;
	flex-wrap: $wrap;
}
@mixin grid-container($rows, $columns, $justify-content) {
	display: grid;
	grid-template-columns: $columns;
	grid-template-rows: $rows;
	justify-content: $justify-content;
}
@mixin text($family, $size) {
	font-family: $family;
	font-size: $size;
}
@mixin even-spacing($margin, $padding) {
	margin: $margin;
	padding: $padding;
}
