@use "../var";
@import "../colors";
@import "../theme";
@import "../typography";

.login,
.forgot-password,
.sign-up {
	width: 100%;
	@include flex-container(column, center, center, nowrap);
	.shadow-card {
		margin-left: auto;
		margin-right: auto;
		.header {
			@include themed() {
				background: t(var.$gradient-logo);
			}
			height: 5%;
			width: 100%;
			border-width: 10px;
			position: fixed;
			top: 0;
		}
		margin: 5rem;
		width: 80%;
		max-width: 20rem;
		max-width: 40rem;
		padding: 0px;
		@include grid-container(75px auto auto 2fr 25px, auto, stretch);
		justify-items: center;
		.card-header {
			width: 100%;
			height: 100%;
			grid-row: 1/2;
			margin-left: auto;
			margin-right: auto;
			align-self: center;
			@include themed() {
				background: t(var.$gradient-logo);
			}
			@include flex-container(row, center, center, nowrap);
			img {
				height: 3rem;
				width: 3rem;
			}
		}
		.title {
			padding: 2rem 0rem 0rem 0rem;
			width: 80%;
			margin-left: auto;
			margin-right: auto;
		}
		.text-field-wrapper {
			width: 80%;
			.input {
				margin-bottom: 1rem;
			}
		}

		.button-container {
			margin-top: 0.25rem;
			width: 100%;
			@include flex-container(column, space-evenly, center, nowrap);
			button {
				width: 70%;
			}
			.links {
				justify-self: start;
				p {
					margin-right: auto;
					margin-left: auto;
					margin: 1rem;
				}
			}
		}
	}
}
