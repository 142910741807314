$light--background: #fcfcfc;
$light--foreground: #eeeeee;
$light--text: #151515;
$light--text-secondary: #5f5f5f;
$light--border: #888;
$light--inactive: #acacac;
$light--link: #0645ad;
$light--text-input: #e0e0e0;

$dark--background: #151515;
$dark--foreground: #212121;
$dark--text: #fcfcfc;
$dark--text-secondary: #ebebeb;
$dark--border: #adadad;
$dark--inactive: #646464;
$dark--link: #5d96f1;
$dark--text-input: #292929;

$light: #fcfcfc;
$dark: #151515;

$inactive: #777;
$danger: #ff0019;
$success: #25c448;
$primary-default: #6840d9;
$primary-light: #8f67ff;
$primary-dark: #59009f;
$accent1-default: #55bdde;
$accent1-light: #8ed6ff;
$accent1-dark: #0085be;
$accent2-default: #f41690;
$accent2-light: #ff61c0;
$accent2-dark: #bc0063;
