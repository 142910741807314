$purple: #8f67ff;

$bg: "bg";
$fg: "fg";
$text: "text";
$text-secondary: "text-secondary";
$border: "border";
$inactive: "inactive";
$success: "success";
$danger: "danger";
$link: "link";
$text-input: "text-input";
$primary-default: "primary-default";
$primary-light: "primary-light";
$primary-dark: "primary-dark";
$accent1-default: "accent1-default";
$accent1-light: "accent1-light";
$accent1-dark: "accent1-dark";
$accent2-default: "accent2-default";
$accent2-light: "accent2-light";
$accent2-dark: "accent2-dark";
$gradient-logo: "gradient-logo";
$gradient-primary: "gradient-primary";
$gradient-accent1: "gradient-accent1";
$gradient-accent2: "gradient-accent2";
$gradient-disabled: "gradient-disabled";
$light: "light";
$dark: "dark";

$font-title: Montserrat, sans-serif;
$font-body: Roboto, sans-serif;

$XXL: 4rem;
$XL: 3rem;
$L: 2rem;
$M: 1.5rem;
$button: 1.25rem;
$default: 1rem;
$S: 0.75rem;
$XS: 0.5rem;
