.two-columns-wrap {
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 0 0.5rem;
	height: 100vh;
	overflow: scroll;

	.column {
		flex-basis: calc(calc(40rem - 100%) * 9999);
		flex-grow: 1;
		&.limited {
			min-width: 25rem;
			max-width: 45rem;
		}
	}
}
@media only screen and (max-width: 600px) {
	.two-columns-wrap {
		flex-direction: column;
	}
}
@media only screen and (min-width: 768px) {
	.two-columns-wrap {
		flex-direction: row;
	}
}
@media only screen and (max-width: 768px) {
	// for screens less than 600 1 column
	.three-column-wrap-center-emphasize {
		// structure used primarily for home screen, but could be used elsewhere

		// secondary are the two side columns, priority is the one that is more important,
		// and will go on top of the non priority one when they get stacked
		height: 100%;
		max-height: 100vh;
		overflow: scroll;
		padding: calc(0.5rem + 0.8vmin);
		.three-column-wrap-center-emphasize {
			height: 100%;
			max-height: 100vh;
			display: grid;
			grid-template-rows: 1fr;
			grid-template-columns: auto auto auto;

			.main {
				grid-row: 1/2;
				grid-column: 3/4;
			}
			.secondary-priority {
				grid-row: 1/2;
				grid-column: 1/2;
			}
			.secondary {
				grid-row: 1/2;
				grid-column: 2/3;
			}
		}
	}
}
@media only screen and (min-width: 768px) and (max-width: 950px) {
	.three-column-wrap-center-emphasize {
		height: 100%;
		max-height: 100vh;
		overflow: scroll;
		display: grid;
		grid-gap: 0.25rem;
		grid-template-columns: 1fr;
		grid-template-rows: auto auto auto;
		padding: calc(0.5rem + 0.8vmin);

		.main {
			justify-self: stretch;
			grid-column: 1/2;
			grid-row: 1/4;
		}
		.secondary-priority {
			justify-self: stretch;
			grid-column: 3/4;
			grid-row: 1/2;
		}
		.secondary {
			justify-self: stretch;
			grid-column: 3/4;
			grid-row: 2/3;
		}
	}
}
@media only screen and (min-width: 950px) {
	.three-column-wrap-center-emphasize {
		display: grid;
		grid-gap: 0.25rem;
		grid-template-columns: 5fr 3fr;
		grid-template-rows: min-content auto;
		align-items: start;
		padding: calc(0.5rem + 0.8vmin);

		.main {
			grid-column: 1/2;
			grid-row: 1/4;
		}
		.secondary-priority {
			grid-column: 2/3;
			grid-row: 1/2;
		}
		.secondary {
			grid-column: 2/3;
			grid-row: 2/3;
		}
	}
}
@media only screen and (min-width: 1200px) {
	.three-column-wrap-center-emphasize {
		display: grid;
		grid-gap: 0.25rem;
		grid-template-columns: 3fr 5fr 3fr;
		grid-template-rows: 1fr;
		padding: calc(0.5rem + 0.8vmin);

		.main {
			justify-self: stretch;
			grid-column: 2/3;
			grid-row: 1/2;
		}
		.secondary-priority {
			justify-self: stretch;
			grid-column: 3/4;
			grid-row: 1/2;
		}
		.secondary {
			justify-self: stretch;
			grid-column: 1/2;
			grid-row: 1/2;
		}
	}
}
