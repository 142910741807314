@use "../var";
@import "../colors";
@import "../theme";
@import "../typography";

.groups-screen {
	display: grid;
	grid-template-columns: 2fr 3fr 3fr 1fr;
	grid-template-rows: 3rem 1fr;
	height: 100%;
	.group-list {
		grid-row: 2/3;
		grid-column: 2/3;
		border-radius: 0.25rem;
		overflow: hidden;
		@include themed() {
			background-color: t(var.$fg);
		}
		.header {
			width: 100%;
			padding: 0.5rem;
			@include themed() {
				background-color: t(var.$primary-default);
			}
			h3 {
				text-align: center;
				@include themed() {
					color: t(var.$light);
				}
			}
		}
		.content {
			padding-bottom: 1rem;
			.group-title {
				padding: 0.75rem 1rem;
				@include themed() {
					background-color: t(var.$primary-dark);
					color: t(var.$light);
				}
			}
			.group-nav-card {
				@include flex-container(row, space-between, center, nowrap);
				width: 100%;
				padding: 0.5rem 1.75rem;
				margin: 0.1rem;

				border-radius: 0.25rem;
				.content {
					width: 100%;
					padding-bottom: 0;
					@include flex-container(row, flex-start, center, nowrap);
					h6 {
						margin: 0 0.75rem;
						text-align: left;
					}
				}
				.icon {
					padding: 0.5rem;
					@include themed() {
						color: t(var.$border);
					}
				}
				.icon:hover {
					transform: scale(1.2);
					@include themed() {
						color: t(var.$text);
					}
				}
			}
			.group-nav-card:hover {
				@include themed() {
					background-color: t(var.$inactive);
				}
			}
		}
	}
}
