@import "./colors";

$themes: (
	default: (
		bg: $light--background,
		fg: $light--foreground,
		text: $light--text,
		text-secondary: $light--text-secondary,
		border: $light--border,
		link: $light--link,
		text-input: $light--text-input,
		inactive: $light--inactive,
		danger: $danger,
		success: $success,
		light: $light,
		dark: $dark,
		primary-default: $primary-default,
		primary-light: $primary-light,
		primary-dark: $primary-dark,
		accent1-default: $accent1-default,
		accent1-light: $accent1-light,
		accent1-dark: $accent1-default,
		accent2-default: $accent2-default,
		accent2-light: $accent2-light,
		accent2-dark: $accent2-dark,
		gradient-logo:
			linear-gradient(
				to right,
				$accent2-default,
				$primary-default,
				$accent1-default
			),
		gradient-primary:
			linear-gradient(to right, $primary-default, $primary-light),
		gradient-accent1:
			linear-gradient(to right, $accent1-default, $accent1-light),
		gradient-accent2:
			linear-gradient(to right, $accent2-default, $accent2-light),
		gradient-disabled:
			linear-gradient(to right, $light--border, $light--inactive),
	),
	dark: (
		bg: $dark--background,
		fg: $dark--foreground,
		text: $dark--text,
		text-secondary: $dark--text-secondary,
		border: $dark--border,
		inactive: $dark--inactive,
		link: $dark--link,
		text-input: $dark--text-input,
		danger: $danger,
		success: $success,
		light: $light,
		dark: $dark,
		primary-default: $primary-default,
		primary-light: $primary-light,
		primary-dark: $primary-dark,
		accent1-default: $accent1-default,
		accent1-light: $accent1-light,
		accent1-dark: $accent1-default,
		accent2-default: $accent2-default,
		accent2-light: $accent2-light,
		accent2-dark: $accent2-dark,
		gradient-logo:
			linear-gradient(
				to right,
				$accent2-default,
				$primary-default,
				$accent1-default
			),
		gradient-primary:
			linear-gradient(to right, $primary-default, $primary-light),
		gradient-accent1:
			linear-gradient(to right, $accent1-default, $accent1-light),
		gradient-accent2:
			linear-gradient(to right, $accent2-default, $accent2-light),
		gradient-disabled: linear-gradient(to right, $dark--inactive, $dark--border),
	),
);

@mixin themed() {
	@each $theme, $map in $themes {
		.theme--#{$theme} & {
			$theme-map: () !global;
			@each $key, $submap in $map {
				$value: map-get(map-get($themes, $theme), "#{$key}");
				$theme-map: map-merge(
					$theme-map,
					(
						$key: $value,
					)
				) !global;
			}
			@content;
			$theme-map: null !global;
		}
	}
}

@function t($key) {
	@return map-get($theme-map, $key);
}
