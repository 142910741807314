@use "../var";
@import "../colors";
@import "../theme";
@import "../typography";

.profile-screen {
	@include flex-container(row, flex-start, flex-start, nowrap);
	flex: 1;
	.profile-content {
		.id,
		.wallet {
			width: min(80%, 25rem);
			min-height: 12rem;
			margin: 1rem;
			border-radius: 1rem;
			overflow: hidden;
			position: relative;
			@include themed() {
				background-color: t(var.$fg);
			}

			.header {
				padding: 0.3rem 0;
				@include themed() {
					background-color: t(var.$primary-default);
				}
			}
			.content {
				z-index: 2;
				padding: 0.5rem 1rem;
				@include flex-container(row, flex-start, flex-start, nowrap);
				div + div {
					padding: 0 0.5rem;
					.id-string {
						@include themed() {
							color: t(var.$danger);
						}
					}
					p {
						z-index: 5;
						font-size: 1.15rem;
					}
				}
			}
			.logo {
				z-index: 1;
				position: absolute;
				height: clamp(5rem, 60%, 20rem);
				margin: 1rem;
				bottom: 0;
				right: 0;
				opacity: 50%;
				color: red;
			}
		}
		.id {
			.content {
				.avatar {
					z-index: 1;
				}
				.avatar-footer {
					z-index: 2;
					padding: 0.75rem;
					@include themed() {
						background-color: t(var.$primary-default);
					}
					margin-top: -5px;
					border-radius: 0 0 5px 5px;
				}
			}
		}
		.wallet {
		}
	}
	.posts-container {
		// width: clamp(30rem, 80%, 50rem);

		.tab-nav {
			@include flex-container(row, center, center, nowrap);
			button {
				flex: 1;
			}
		}
		.posts {
			@include flex-container(row, center, flex-start, nowrap);
		}
	}
}
.notifications-screen,
.profile-settings-screen {
	height: 5%;
	.notification-container,
	.settings-container {
		overflow: scroll;
		height: 100%;
		margin: 1rem auto;
		padding: 1rem;
		border-radius: 0.5rem;
		width: 50%;
		-ms-overflow-style: none;

		@include themed() {
			background-color: t(var.$bg);
		}
	}
	.notification-container::-webkit-scrollbar,
	.settings-container::-webkit-scrollbar {
		background: transparent;
		width: 0;
	}
}
.notification-screen {
	.notification-container {
		.notification {
			max-width: 50rem;
			margin: 0.15rem auto;
			padding: 0.5rem 0.5rem;
			border-radius: 0.5rem;

			@include flex-container(row, flex-start, center, nowrap);
			@include themed() {
				background-color: t(var.$fg);
			}
			img {
				width: 3rem;
				padding: 0 0.5rem 0 0;
			}
			.header {
				@include flex-container(row, flex-start, center, nowrap);
			}
		}
	}
}
.profile-settings-screen {
	.settings-container {
		@include themed() {
			background-color: t(var.$fg);
		}
	}
}
