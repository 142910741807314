@use "../var";
@import "../colors";
@import "../theme";
@import "../typography";
.home-screen-wrapper {
	height: 100%;
	max-height: 100vh;
	.home-screen {
		.home-left {
			.add-categories {
				.content {
					margin-top: 0;
					@include themed() {
						background-color: t(var.$fg);
					}
					h6 {
						text-align: center;
						padding-bottom: 1rem;
					}
					.button {
						margin: 0 auto;
					}
				}
			}
			.complete-profile,
			.get-verified {
				margin-top: 0;

				p {
					padding: 1rem 0;
				}
			}
		}
		.home-center {
			border-radius: 1rem;
			padding: 1rem;
			height: 100%;
			max-height: 100vh;
			@include themed() {
				background-color: t(var.$fg);
			}

			@media only screen and (min-width: 768px) {
				.home-feed {
					height: 100%;
					overflow-y: scroll;
					-ms-overflow-style: none;
				}
				.home-feed::-webkit-scrollbar {
					background: transparent;
					width: 0;
				}
			}
		}
		.home-right {
			@include themed() {
				background-color: t(var.$bg);
			}
			.current-topic {
				.content {
					margin-top: 0;
					@include flex-container(row, space-around, center, nowrap);
					.info {
						flex: 1;
						p {
							font-size: var.$S;
						}
						.stats {
							@include themed() {
								border-color: t(var.$border);
							}
							border-width: 2px;
							border-style: solid;
							border-radius: 5px;
							width: min-content;
							padding: 0.25rem 0.5rem;
							margin: 0.5rem 0;
							@include flex-container(row, flex-start, center, nowrap);
							p {
								@include flex-container(row, center, center, nowrap);
								h6 {
									padding: 0 0.5rem 0 0.25rem;
								}
							}
						}
					}
					.buttons {
						flex: 0.75;
						@include flex-container(column, flex-start, center, nowrap);
						button {
							width: 100%;
							margin: 0.5rem auto;
						}
					}
				}
			}
		}
	}
}
.create-post {
	justify-self: stretch;
	@include themed() {
		background-color: t(var.$bg);
	}
	grid-column: 1/4;
	grid-row: 2/3;
	padding: 10px;
	display: grid;
	grid-template-columns: 2fr 3fr 2fr;
	column-gap: 1rem;
	.info-popup {
		height: min-content;
		grid-column: 1/2;
		p {
			padding-top: 1rem;
		}
	}
	.post-button-container {
		grid-column: 3/4;
		height: min-content;
		p {
			padding: 1rem 0.5rem;
		}
	}
	.create-post-content {
		grid-column: 2/3;
		grid-row: 1/2;
		.priority-container {
			margin-left: auto;
			margin-right: auto;
			padding: 1rem;
			border-radius: 0.25rem;
			@include themed() {
				background-color: t(var.$fg);
			}

			.header {
				@include flex-container(row, space-between, center, nowrap);
			}
			.range {
				h6 {
					padding-bottom: 0.5rem;
				}
				hr + div {
					@include flex-container(row, flex-start, baseline, wrap);
					h6 + div {
						@include flex-container(row, flex-start, center, wrap);
						@include themed() {
							background-color: t(var.$text-input);
						}
						border-radius: 0.25rem;
						margin: 0 1rem;
						button:hover {
							@include themed() {
								background-color: t(var.$primary-light);
								color: t(var.$light);
								transition: color 50ms linear;
							}
						}
						button:active,
						button:focus {
							@include themed() {
								background-color: t(var.$primary-default);
								color: t(var.$light);
							}
						}
					}
				}
			}
		}

		.options-header {
			padding: 0.75rem 0;
			margin: 0.5rem 0;
			border-radius: 0.25rem;
			width: 100%;
			@include themed() {
				background-color: t(var.$fg);
			}
			@include flex-container(row, center, center, nowrap);
			switch {
				margin: 0 0.5rem;
			}
			h6 {
				padding: 0 0.5rem;
			}
		}
		.option-container {
			width: 100%;
			margin: 0.5rem 0;
			border-radius: 0.5rem;
			p {
				margin: 0.75rem;
			}
			@include themed() {
				background-color: t(var.$fg);
			}
			.option {
				padding: 0.25rem 1rem;
				margin: 0.5rem 0.5rem;
				border-radius: 0.5rem;
				p {
					padding: 0;
				}
				@include flex-container(row, space-between, baseline, nowrap);

				@include themed() {
					background-color: t(var.$bg);
				}
			}
		}
		.add-options-container {
			@include flex-container(row, flex-start, center, nowrap);
		}
	}
}
