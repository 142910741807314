@use './var';
@use './extend';
@import "./colors";
@import "./mixins";
@import "./theme";

// STYLE: CONTAINERS

.empty-list-container {
	@include flex-container(column, center, center, nowrap);
	@include themed() {
		background-color: t(var.$bg);
	}
	padding: 1.5rem;
	margin-left: auto;
	margin-right: auto;
	border-radius: 0.75rem;
	width: 100%;
	box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.25);
}
.explore-topic-button {
	.stats-container {
		@include flex-container(row, flex-end, center, nowrap);
	}
}
.info-popup {
	@include themed() {
		background-color: t(var.$fg);
	}

	padding: 1.25rem 0.75rem;
	border-radius: 0.5rem;
}
// STYLE: SETTINGS
.preferences-container {
	width: 100%;
	.sub-title {
		width: min-content;
	}
	.preference {
		width: 100%;
		border-radius: 1rem;
		padding: 0.75rem;
		margin: 0.5rem 0rem;
		box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.25);
		@include themed() {
			background-color: t(var.$bg);
		}
		label {
			margin-bottom: 0.5rem;
			@include flex-container(row, space-between, flex-start, nowrap);
		}
	}
}
// STYLE: INPUTS

.text-field-wrapper {
	margin: 0.75rem 0rem;
	flex: 1;
	width: 100%;
	@include flex-container(column, flex-start, center, nowrap);
	.label {
		@include themed() {
			color: t(var.$text-secondary);
		}
	}
	.input {
		margin-bottom: 0.5rem;
		width: 100%;
		@include themed() {
			background-color: t(var.$text-input);
			color: t(var.$text);
		}
	}

	.primary {
		@include themed() {
			background-color: t(var.$fg);
			color: t(var.$text);
		}
	}
	.password {
		-webkit-text-security: disc;
	}
	.bg {
		@include themed() {
			background-color: t(var.$inactive);
			color: t(var.$text);
		}
	}
	.fg {
		@include themed() {
			background-color: t(var.$fg);
		}
	}

	.text-field {
		width: 100%;
		margin: 0.5rem;
		padding: 1rem;
		@include themed() {
			background-color: t(var.$bg);
			color: t(var.$text);
		}
	}
	.input-text {
		@include themed() {
			color: t(var.$text);
		}
	}
	.input-placeholder {
		@include themed() {
			color: t(var.$text);
		}
	}
}
// STYLE: BUTTONS
.MuiIconButton-colorPrimary {
	color: var.$primary-default;
}
.MuiIconButton-colorSecondary {
	@include themed() {
		color: t(var.$text);
		opacity: 80%;
	}
}
.MuiButton-outlined {
	@include themed() {
		color: t(var.$text);
		border-color: t(var.$text);
	}
}
// ICONS
.MuiIcon-colorPrimary {
	color: var.$primary-default;
}
.MuiIcon-colorSecondary {
	@include themed() {
		color: t(var.$text);
		opacity: 80%;
	}
}

.nav-button {
	padding: 0.5rem auto 0.5rem 0.5rem;
}
//
.icon {
	border-radius: 50%;
	@include flex-container(row, center, center, nowrap);
}

.vertical-hr {
	@include themed() {
		background-color: t(var.$text-secondary);
	}
	height: 80%;
	width: 1px;
	opacity: 0.8;
	margin: 0 0.5rem;
}

//.button-wrapper {
// 	width: 100%;
// 	border-radius: 0.6rem;

// 	.logo {
// 		@extend .button;
// 		@include themed() {
// 			background: t(var.$gradient-logo);
// 			color: t(var.$light);
// 		}
// 		border-width: 1px;
// 		border-style: hidden;
// 	}
// 	.large {
// 		padding: 0.6rem 1.2rem;
// 		min-width: 8rem;
// 		border-radius: 0.6rem;
// 		p {
// 			@include text(var.$font-title, var.$M);
// 		}
// 	}

// 	.default {
// 		padding: 0.45rem 0.9rem;
// 		min-width: 6rem;
// 		border-radius: 0.625rem;
// 		p {
// 			@include text(var.$font-title, var.$default);
// 		}
// 	}
// 	.small {
// 		padding: 0.1rem 0.1rem;
// 		border-radius: 0.5rem;
// 		p {
// 			@include text(var.$font-title, var.$S);
// 		}
// 	}
// 	.primary {
// 		@extend .button;
// 		@include themed() {
// 			background: t(var.$gradient-primary);
// 			// color: t(var.$light);
// 		}
// 		border-width: 1px;
// 		border-style: hidden;
// 		p {
// 			@include themed() {
// 				color: t(var.$light);
// 			}
// 		}
// 	}
// 	.secondary {
// 		@extend .button;
// 		@include themed() {
// 			outline-color: t(var.$text);
// 			background-color: t(var.$bg);
// 		}
// 		outline-width: 1px;
// 		outline-offset: 1px;
// 		border-style: solid;
// 	}

// 	.disabled {
// 		@extend .button;
// 		@include themed() {
// 			background: t(var.$gradient-disabled);
// 			color: t(var.$light);
// 		}
// 	}
// 	.nav {
// 		@extend .nav-button;
// 	}
// }
// .button-wrapper:hover {
// 	transform: scale(1.02);
// 	opacity: 0.7;
// 	// box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
// }

// .icon-wrapper {
// 	.primary {
// 		@extend .icon;
// 		@include themed() {
// 			background: t(var.$gradient-primary);
// 		}
// 	}
// 	.secondary {
// 		@extend .icon;

// 		border-width: 1px;
// 		border-style: solid;
// 		@include themed() {
// 			border-color: t(var.$border);
// 		}
// 	}
// 	.disabled {
// 		@extend .icon;

// 		@include themed() {
// 			background: t(var.$gradient-disabled);
// 		}
// 	}
// 	.large {
// 		width: 3.25rem;
// 		height: 3.25rem;
// 	}
// 	.default {
// 		width: 2.25rem;
// 		height: 2.25rem;
// 	}
// 	.small {
// 		width: 1.25rem;
// 		height: 1.25rem;
// 	}
// 	.large-container {
// 		width: 3.75rem;
// 		height: 3.75rem;
// 	}
// 	.default-container {
// 		width: 2.75rem;
// 		height: 2.75rem;
// 	}
// 	.small-container {
// 		width: 1.75rem;
// 		height: 1.75rem;
// 	}
// }
